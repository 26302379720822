<template functional>
	<div class="tabletWide:py-48 desktop:py-56 tabletWide:grid tabletWide:grid-cols-12 tabletWide:gap-16 tabletWide:items-center" :class="[data.class, data.staticClass]">
		<div class="tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:col-start-7">
			<component :is="injections.components.layoutPost" type="center" class="space-y-32">
				<header>
					<slot name="title">
						<h2 class="font-bold text-32 tablet:text-40 desktop:text-48 whitespace-nowrap">
							GO all across NZ
						</h2>
					</slot>
				</header>

				<slot name="content">
					<p class="text-18 desktop:text-22">We have nine car rental branches spread evenly throughout New Zealand, in the major destinations of Auckland, Wellington, Christchurch, Nelson, Queenstown, Dunedin and Invercargill so that you’re never far from a GO rental car.</p>
				</slot>

				<div class="relative">
					<ul class="relative z-10 flex-grow">
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/auckland-city/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Auckland City</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('AKLC')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/auckland-airport/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Auckland Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('AKLA')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/waiheke/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Waiheke Island</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('WKE')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/wellington/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Wellington Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('WLG')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/nelson/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Nelson Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('NSN')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/christchurch/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Christchurch Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('CHC')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/queenstown/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Queenstown Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('ZQN')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/dunedin/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Dunedin Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('DUD')">{{ props.tagLocations.label }}</span>
						</li>
						<li class="flex space-x-12 items-center leading-16 py-10">
							<svg-icon name="dot" class="text-goPink iconSize-16" />
							<nuxtLink to="/rental-car-locations/invercargill-airport/" class="text-goPink hover:text-goPinkDark active:text-goPinkDarker font-bold">Invercargill Airport</nuxtLink>
							<span class="text-goGrayDark" v-if="'tagLocations' in props && props.tagLocations.locations.includes('IVC')">{{ props.tagLocations.label }}</span>
						</li>
					</ul>

					<picture class="tabletWide:hidden absolute h-full top-0 right-0 tablet:-right-56">
						<img src="/images/blank.svg" data-src="/images/nzMap.png" alt="Map of New Zealand" decoding="async" class="lazyload w-full h-full object-right object-contain tabletWide:object-center tabletWide:object-cover">
					</picture>
				</div>
			</component>
		</div>

		<div class="hidden tabletWide:unset tabletWide:col-span-6">
			<div class="relative tablet:pl-48">
				<picture class="top-0 w-full h-full">
					<img src="/images/blank.svg" data-src="/images/nzMap.png" alt="Map of New Zealand" decoding="async" class="lazyload w-full h-full">
				</picture>

				<ul class="absolute top-0 w-full h-full z-10">
					<li class="flex items-center leading-16 absolute font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top: 18.5%;left: 57.5%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/auckland-city/">Auckland City</nuxtLink>
					</li>
					<li class="flex items-center leading-16 absolute w-6/12 font-bold text-14 flex-row-reverse text-right text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top: 19%;left: 12.9%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/auckland-airport/">Auckland <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex flex-row items-center leading-16 absolute w-6/12 font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top: 17%;left: 60%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/waiheke/">Waiheke Island</nuxtLink>
					</li>
					<li class="flex flex-row items-center leading-16 absolute w-6/12 font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top:52%;left:58%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/wellington/">Wellington <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex items-center leading-16 absolute w-6/12 font-bold text-14 flex-row-reverse text-right text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top: 52%;left: 1%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/nelson/">Nelson <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex flex-row items-center leading-16 absolute w-6/12 font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top:69%;left:39.2%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/christchurch/">Christchurch <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex items-center leading-16 absolute w-6/12 font-bold text-14 flex-row-reverse text-right text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top:81%;left:-29.5%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/queenstown/">Queenstown <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex flex-row items-center leading-16 absolute w-6/12 font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top:88.1%;left:21.7%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/dunedin/">Dunedin <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
					<li class="flex flex-row items-center leading-16 absolute w-6/12 font-bold text-14 text-goPink hover:text-goPinkDark active:text-goPinkDarker" style="top:92.1%;left:11.7%;">
						<svg-icon name="dot" class="iconSize-24 mx-12" />
						<nuxtLink to="/rental-car-locations/invercargill-airport/">Invercargill <br class="hidden tabletWide:unset">Airport</nuxtLink>
					</li>
				</ul>
			</div>
		</div>

		<div v-if="'footer' in $scopedSlots" class="col-span-12 p-16 tablet:p-48 tablet:pr-0 tabletWide:p-0 tabletWide:pt-56 tabletWide:pl-56 space-y-32">
			<slot name="footer" />
		</div>
	</div>
</template>

<script>
import layoutPost from '~/components/layoutPost.vue';

export default {
	inject: {
		components: {
			default: {
				layoutPost
			}
		}
	},
};
</script>
