<template functional>
	<span :class="[data.class, data.staticClass]" v-html="$options.methods.applyStyle(props.markdown)" />
</template>

<script>
import { marked } from 'marked' // eslint-disable-line

export default {
	props: {
		markdown: {
			type: String,
			required: true,
		},
	},
	methods: {
		applyStyle(markdown) {
			return marked.parseInline(markdown);
		},
	}
};
</script>
