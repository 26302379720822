const striptags = (str) => str.replace(/<[^>]*>?/gm, '')

export function getSchemaFaqPage (faqPage) {
	if (!faqPage) return null

	return {
		'@context' : 'http://schema.org',
		'@type'    : 'FAQPage',
		mainEntity : faqPage.map((faq) => ({
			'@type'        : 'Question',
			name           : striptags(faq.question),
			acceptedAnswer : {
				'@type' : 'Answer',
				text    : striptags(faq.answer),
			},
		})),
	}
}

export function getSchemaOfferCatalog (props) {
	if (!props) return null
	const { name, items } = props

	return {
		'@context'        : 'http://schema.org',
		'@type'           : 'OfferCatalog',
		name,
		itemListElement: items.map((item) => ({
			'@type'       : 'Offer',
			itemOffered   : 'Service',
			name          : item.name,
			areaServed    : item.areaServed,
			category      : item.category,
			description   : striptags(item.description),
			image         : item.image,
		})),
	}
}
