var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"\n\tflex flex-col\n\th-full\n\tspace-y-32\n\tp-16\n\t",class:[
		_vm.data.class,
		_vm.data.staticClass,
		_vm.props.type === 'left' ? 'tablet:p-48 tabletWide:p-0 tablet:w-8/12 tabletWide:w-10/12' : '',
		_vm.props.type === 'center' ? 'tablet:p-48 tabletWide:p-0 tablet:w-8/12 tabletWide:mx-auto' : '',
		_vm.props.type === 'full' ? 'tablet:p-0 w-full' : '',
		_vm.props.type === 'widest' ? 'tablet:p-0 tabletWide:w-10/12' : '',
		_vm.props.type === 'wide' ? 'tablet:p-0 tabletWide:w-8/12' : '',
		_vm.props.type === 'start' ? 'place-content-start' : 'place-content-center' ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }