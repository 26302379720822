export const getPageMetaData = async ($strapi, slug) => {
	try {
		const { data: pageMetaData } = await $strapi.$get(`/page-meta-data/?filters[slug]=${slug}`)
		const pageMeta = pageMetaData[0]?.attributes
		if (!pageMeta) return null
		return {
			title: pageMeta.title,
			description: pageMeta.description
		}
	} catch (error) {
		return null
	}
}
