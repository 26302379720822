<template functional>
	<aside class="rounded-6" :class="[data.class, data.staticClass]">
		<blockquote class="h-full flex flex-col">
			<div class="p-24 flex flex-col flex-1 space-y-16">
				<p class="flex-1 font-bold text-goPinkDark">{{ props.text }}</p>
				<cite class="flex items-center not-italic">
					<img v-if="!!props.avatar" :data-src="props.avatar" src="/images/blank.svg" :alt="props.alt" decoding="async" class="lazyload rounded-oval mr-16" width="56" height="56" />
					<div class="text-14 leading-20">
						<div class="font-bold">{{ props.name }}</div>
						<div v-if="!!props.detail" class="text-goGrayDarker">{{ props.detail }}</div>
					</div>
				</cite>
			</div>
			<footer v-if="!!props.vehicle && !!props.date" class="px-24 py-8 border-t border-black/10">
				<p class="text-14 text-goGrayDark">
					<span>{{ props.vehicle }}</span> <br />
					<span>{{ props.date }}</span>
				</p>
			</footer>
		</blockquote>
	</aside>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		text: {
			type: String,
			required: true,
		},
		avatar: {
			type: String,
			default: '',
		},
		alt: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		detail: {
			type: String,
			default: '',
		},
		vehicle: {
			type: String,
			default: "",
		},
		date: {
			type: String,
			default: "",
		},
	},
};
</script>
